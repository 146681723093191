<template>
    <div class="navbarmobileFix">
    <nav class="d-none d-lg-block three-nav">
    <ul class="three-nav navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
    <li class="three-nav" @click="receiveConsultation()">
        <div>
            <img src="~@/assets/images1/doctor-hospital.png" style="width: 40px;">
        </div>
    </li>
    <li class="three-nav" @click="getCase()">
        <div>
            <img src="~@/assets/images/one-one.png" style="width: 40px;">
        </div>
    </li>
    <li class="three-nav" @click="joinPatient()">
        <div>
            <img src="~@/assets/images/patientgroup.png" style="width: 40px;">
        </div>
    </li>
    <li class="three-nav" @click="accessClinical()">
        <div>
            <img src="~@/assets/img/medical-knowledge.png" style="width: 40px;">
        </div>
    </li>
    <!-- <li class="three-nav" @click="postConsultation()">
        <div>
            <img src="~@/assets/images/group.png" style="width: 40px;">
        </div>
    </li> -->
    </ul>
    </nav>

    <section class="banner-text offer-consultation">
    <div class="container text-align">
    <div class="row">
        <!-- FOR DESKTOP  -->
        <div class="col-12  banner-sub d-none d-lg-block">
            <h1 class="offer-consultationTextHeading">Digitize your consultation records</h1>
        </div>
        <!-- FOR MOBILE  -->
        <div class="col-12  banner-sub d-block d-lg-none">
            <h1 class="offer-consultationTextHeading mb-0">Digitize your</h1>
            <h1 class="offer-consultationTextHeading mb-0">consultation records</h1>
        </div>
    </div>
    <div class="row">
        <!-- FOR DESKTOP  -->
        <div class="col-12 d-none d-lg-block text-center banner-sub-content offer-consultationText">
            <h3 class="mb-0">Digitizing your consultation records need not require </h3>
            <h3>computers/ tablets/ digital pens.</h3>
            <h3 class="mt-4 mb-0">With RxIx, your regular prescription pad and</h3>
            <h3 >the camera in your mobile suffice.</h3>
            <h3 class="mt-4">No device stands between you and your patient.</h3>
        </div>
    </div>
    <div class="row">
        <!-- FOR MOBILE  -->
        <div class="col-12 d-block d-lg-none text-center banner-sub-content offer-consultationText">
            <h3 class="mb-0">Digitizing your consultation</h3>
            <h3 class="mb-0">records need not require</h3>
            <h3 class="mb-0">computers/ tablets/ digital</h3>
            <h3>pens.</h3>
            <h3 class="mt-4 mb-0">With RxIx, your regular</h3>
            <h3 class="mb-0">prescription pad and the</h3>
            <h3>camera in your mobile suffice.</h3>
            <h3 class="mt-4 mb-0">No device stands between you </h3>
            <h3 class="mb-0">and your patient.</h3>
        </div>
    </div>
    </div>
    </section>
        <section class="banner-text mt-3">
            <div class="container text-align">
                <!-- FOR DESKTOP  -->
    <div class="samplecaseHistoryText">
        <h2 class="spacetopInsamplehistoryText mb-0">View the steps</h2>
        <h2>to digitize your record</h2>
    </div>
    <div class="row samplehistory">
    <div class="text align text-center p-0" >
        <div class="ditigal-consultation-box" >
        <div >
            <img src="@/assets/images/searching.png" class="digital-imgs" >
            <p class="mb-0 mt-3">Search and verify</p>
            <p class="mb-0">patient's mobile</p>
            <p>number</p>
        </div>
        <div>
            <img src="@/assets/images/paper.png" class="digital-imgs" >
            <p class="mb-0 mt-3">View patient's</p>
            <p class="mb-0">records, across</p>
            <p>doctors</p>
        </div>
        <div >
            <img src="@/assets/images/keyboard.png" class="digital-imgs" >
            <p class="mb-0 mt-3">Create case</p>
            <p class="mb-0">sheet with your</p>
            <p>mobile keypad</p>
        </div>
        <div >
            <img src="@/assets/images/agreement.png" class="digital-imgs" >
            <p class="mb-0 mt-3">Write prescription </p>
            <p class="mb-0">with your</p>
            <p>regular pad</p>
        </div>
        <div >
            <img src="@/assets/images/phone-image.png" class="digital-imgs">
            <p class="mb-0 mt-3">Take photo of </p>
            <p>the prescription</p>
        </div>
        <div >
            <button class="btn closeConsultation">Close consultation</button>
            <p class="mb-0 mt-3">Click the Close</p>
            <p class="mb-0">Consultation</p>
            <p>button</p>
        </div>
    </div>
</div>
    <!-- <div class="card card-box cardDesign" style="margin-bottom: 30px;">
    <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active container" id="pills-new" role="tabpanel"
    aria-labelledby="pills-new-tab">
    <div class="row mt-3">
    <div class="col">
    <label class=" SizeHeadingText ">Enter phone number to search patient details</label>
    <p class="form-control-number"> 98410xxxxx</p>
    </div>
    <div class="col my-4" id="patient_list">
    <button type="button" class="btn text-white submit-btn-color">Search</button>
    </div>
    </div>
    <div class="hr-border mb-3"></div>
    <div class="row mt-3 mb-3">
    <div class="col-sm-12">
    <label class=" SizeHeadingText">Registered Patients</label>
    </div>
    <div class="row">
    <div class="col-3">
    <div class="image-fix icon">
    <div class="text-center name-fix">Name</div>
    <img class="img-men icon mt-2 mb-2" src="@/assets/images/profile.png" />
    </div>
    </div>
    </div>
    </div>
    <div class="hr-border mb-3"></div>
    <div>
    <div class="card-title card-text">
    <div class="row text-align text-center">
    <div class="col-12">
    <p class=" font-disable">Patient name hidden</p>
    </div>
    </div>
    <div class="patientGenderAgeDetailDiv">
    <div style="padding-right: 50px;">
    <p>Age </p>
    </div>
    <div>
    <p>Gender</p>
    </div>
    </div>
    </div>
    <div>
    <h5>Past Consultations</h5>
    </div>
    <div class="row card-title2">
    <div class="col-4">
    <p class="nav-link bg-color text-white text-center cursor-pointer my-1"
    @click="marThree()" :class="[marThreeDate === true ? 'disableButton' : '']">
    3-Mar-23</p>
    </div>
    <div class="col-4">
    <p class="nav-link bg-color text-white text-center cursor-pointer my-1"
    @click="janTwentyOne()"
    :class="[janTwentyOneDate === true ? 'disableButton' : '']">21-Jan-23</p>
    </div>
    <div class="col-4">
    <p class="nav-link bg-color text-white text-center cursor-pointer my-1"
    @click="janSixteen()"
    :class="[janSixteenDate === true ? 'disableButton' : '']">
    16-Jan-23</p>
    </div>
    </div>
    <div class=" row card-title2 mt-3">
    <div class="col-4">
    <p class="nav-link bg-color text-white text-center cursor-pointer my-1"
    @click="janfivteen()"
    :class="[janfivteenDate === true ? 'disableButton' : '']">
    15-Jan-23</p>
    </div>
    <div class="col-8">
    <p class="nav-link bg-color text-white text-center cursor-pointer my-1"
    @click="janSix()" :class="[janSixDate === true ? 'disableButton' : '']">
    6-Jan-23</p>
    </div>
    </div>
    <div class="hr-border mb-3"></div>
    <div>
    <p class="pastConsultDate mt-4" v-if="marThreeDate">3-Mar-23</p>
    <p class="pastConsultDate mt-4" v-if="janTwentyOneDate">21-Jan-23</p>
    <p class="pastConsultDate mt-4" v-if="janSixteenDate">16-Jan-23</p>
    <p class="pastConsultDate mt-4" v-if="janfivteenDate">15-Jan-23</p>
    <p class="pastConsultDate mt-4" v-if="janSixDate">6-Jan-23</p>
    <div class="row">
    <div class="col-12 mt-3">
    <p class=" font-disable">Dr. doctor name hidden</p>
    </div>
    </div>
    <div class="row">
    <div class="col-12 card-title1">
    <h5>Case sheet</h5>
    </div>
    </div>
    <div class="my-2">
    <div class="borderForDateSheet form-cont" v-if="marThreeDate">
    <p class=" ">Complaints of itching in both
    groins</p>
    <p class=" ">Recently detected Hyptertensive</p>
    <p class="">On treatment</p>
    <p class="">Suggestive of Tinea cruris</p>
    </div>
    <div class="borderForDateSheet form-cont" v-if=janSixDate>
    <p class="">52 years old male</p>
    <p class=""> h/o headache/ giddiness on and off for the last 3
    months</p>
    <p class=""> No other complaints</p>
    <p class=""> Not a known Hypertensive/ Diabetic</p>
    <p class=""> Family h/o Hypertension +</p>
    <p class=""> No other co-morbidities</p>
    <div class="mt-4">
    <p class="">On examintaion</p>
    <p class=""> HR - 86/mt</p>
    <p class=""> SpO2 - 98% RA</p>
    <p class=""> BP - 170/100 Right Upper Limb</p>
    <p class="">160/94 Left Upper Limb</p>
    </div>
    <div class="mt-4">
    <p class="">CVS - S1S2 +
    RS - Normal breath sounds
    </p>
    <p class="">Dx - Systemic Hypertension</p>
    </div>
    </div>
    <div class="borderForDateSheet form-cont" v-if="janfivteenDate">
    <p class="">Patient reviewed</p>
    <p class=""> No headache or giddiness now</p>
    <p class="">Complaints of neck pain on and off</p>

    <div class="mt-4">
    <p class=""> On examination</p>
    <p class=""> SpO2 - 99%</p>
    <p class="">HR - 82/mt</p>
    <p class=""> BP - 140/84 mm Hg R UL</p>
    <p class=""> 130/80 mm Hg L UL</p>
    </div>

    <div class="mt-4">
    <p class="">Blood investigations showed</p>
    <p class="">Increased LDL</p>
    <p class="">Other results normal</p>
    <p class="">Ecg - within normal limits</p>
    <p class="">2D Echo - Adequate LV function</p>
    </div>

    </div>
    <div class="borderForDateSheet form-cont" v-if="janSixteenDate">
    <p class="">Male 52 years old</p>
    <p class="">Referred with complaints of neck pain for the past 5
    days</p>
    <p class="">No radiating pain to the arms</p>
    <p class="">Pain aggravated on extension of neck</p>
    <div class="mt-4">
    <p class="">
    Dx
    </p>
    <p class="">
    Cervical spondylosis
    </p>
    </div>
    </div>
    <div class="borderForDateSheet form-cont" v-if="janTwentyOneDate">
    <p class="">Diagnosed as Cerival </p>
    <p class=""> Patient is symptomatically better</p>
    <p class=""> Come for review</p>
    </div>
    </div>
    <div class="row">
    <div class="col-12 card-title1">
    <h5>Prescription</h5>
    </div>
    </div>
    <div class="my-3" v-if="marThreeDate">
    <img class="notesImg" src="@/assets/images/Prescription-mar-3.png">
    </div>
    <div class="my-3" v-if="janSixDate">
    <img class="jan-six-image" src="@/assets/images/jan-6.png">
    </div>
    <div class="my-3" v-if="janfivteenDate">
    <img class="jan-six-image" src="@/assets/images/jan-fivteen.png">
    </div>
    <div class="my-3" v-if="janSixteenDate">
    <img class="jan-six-image" src="@/assets/images/jan-sixteen.png">
    </div>
    <div class="my-3" v-if="janTwentyOneDate">
    <img class="jan-six-image" src="@/assets/images/jan-twentyOne.png">
    </div>
    </div>
    <div class="mt-3" v-if="ToggleButton">
    <div class='imageUploadDetails'>

    <div class='row popup'>
    <div class="col imagesTexts">
    <div>
    <img class="prescription-image"
    src="@/assets/images/notes-image.png">
    <p class="prescriptionParaFont">Write prescription on your
    pad</p>
    </div>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <div>
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    </div>
    <div class="col imagesTexts">
    <div>
    <img class="prescription-image"
    src="@/assets/images/phone-image.png">
    <p class="prescriptionParaFont">Take its photo using your
    mobile</p>
    </div>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <div>
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    </div>
    <div class="col imagesTexts">
    <div>
    <img class="prescription-image"
    src="@/assets/images/upload-image.png">
    <p class="prescriptionParaFont">Upload the prescription</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="row mt-4">
    <div class="col-lg-4 col-md-5 col-sm-6 col-10 mb-0">
    <label class="consultationdateLabel">Upload prescription</label>
    </div>
    <div class="col-lg-8 col-md-7 col-sm-6 col-2 question-icon mb-0" @click="toggle()">
    <font-awesome-icon :icon="['fas', 'question-circle']"
    style="font-size: 25px;" />
    </div>
    </div>
    <div class="row ">

    <div class="col-lg-4 col-md-5 col-sm-5 col-10">
    <p class="selectfile" >Select file</p>                                        
    </div>
    </div>
    <div class="row mb-3 mt-3">
    <label class="consultationdateLabel">Next consultation date</label>
    <div class="col-12">
    <p class="consultateDateBox" v-if="janSixDate">15-Jan-23</p>
    <p class="consultateDateBox" v-if="janfivteenDate">15-Mar-23</p>
    <p class="consultateDateBox" v-if="janSixteenDate">21-Jan-23</p>
    <input type="text" class="consultateDateBox" v-if="janTwentyOneDate" />
    <p class="consultateDateBox" v-if="marThreeDate">17-Mar-23</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div> -->
    </div>
    <!-- SIGN-UP FOR DESKTOP  -->
    <div class="d-none d-lg-block" >
    <div style="display: flex;justify-content: end;">
    <button class="butt btn btn-blue-color" v-on:click="offerCon(4)">SignUp</button>
    </div>
    </div>
    <!-- SIGN-UP FOR MOBILE  -->
    <div class="d-block d-lg-none">
     <button class="btn btn-blue-color mobile-signupbtn" v-on:click="offerCon(4)">Sign Up</button>
    </div>
</div>
</section>
</div>
  <MobileNavbar></MobileNavbar>
</template>
<script>
import { defineComponent } from "vue";
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios"
export default defineComponent({
    components: {
        MobileNavbar
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            offerConsult: true,
            currentPath: "",
            // issubmit: false,
            janSixDate: false,
            marThreeDate: true,
            janfivteenDate: false,
            janSixteenDate: false,
            janTwentyOneDate: false,
            ToggleButton:false
        };
    },
    created: function () {
        this.currentPath = this.$route.path.split('/')[2]
    },
    methods: {
        toggle(){
            this.ToggleButton = !this.ToggleButton
        },
        marThree() {
            this.marThreeDate = true
            this.janSixteenDate = false
            this.janTwentyOneDate = false
            this.janfivteenDate = false
            this.janSixDate = false

        },
        janTwentyOne() {
            this.janTwentyOneDate = true
            this.janSixteenDate = false
            this.marThreeDate = false
            this.janfivteenDate = false
            this.janSixDate = false
        },
        janSixteen() {
            this.janSixteenDate = true
            this.marThreeDate = false
            this.janfivteenDate = false
            this.janSixDate = false
            this.janTwentyOneDate = false
        },
        janfivteen() {
            this.janfivteenDate = true;
            this.marThreeDate = false
            this.janSixDate = false
            this.janSixteenDate = false
            this.janTwentyOneDate = false
        },
        janSix() {
            this.janSixDate = true
            this.marThreeDate = false
            this.janfivteenDate = false
            this.janSixteenDate = false
            this.janTwentyOneDate = false
        },
        receiveConsultation() {
            this.$router.push('/doctor/consultationrequests');
        },
        getCase() {
            this.$router.push('/doctor/communities');
        },
        joinPatient() {
            this.$router.push('/doctor/patientgroups');
        },
        accessClinical() {
            this.$router.push('/doctor/clinicalresources');
        },
        postConsultation() {
            this.$router.push('/presign/postconsultation');
        },
        async offerCon(offerConsultation) {
            let payload = {
                offerConsultationID:JSON.stringify(offerConsultation),
                card:'doctor',
                routeName: 'offerconsultation'
            }
            this.$router.push({ path: '/signup', query: payload });
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .put(`${BASE_API_URL}/hcps/generate-otp`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(response => {
                    if (response.status == 200) {
                        this.offerConsult = !this.offerConsult
                        // window.location.href = `/index/${this.currentPath}`;
                    }
                })
                .catch(error => {
                    console.log(error?.response)
                        .then(() => {
                            if (error?.response.status == 404) {
                                this.offerConsult = false;
                            }
                            else if (error?.response.status == 400) {
                                return false;
                            }
                        });
                });
        }
    },
    name: "HomePageB",
    props: {},
});    
</script>
<style>
.digital-imgs{
    width: 100px;
}
.closeConsultation{
border:2px solid #000 !important;
 cursor: auto !important; 
 width: 125px;
  margin: auto;
}
.ditigal-consultation-box div p{
    font-size: 20px;
}
.ditigal-consultation-box{
 display: grid;
 grid-gap: 20px; 
 grid-template-columns: repeat(3, 1fr);
}
.popup{
    display: inline-flex;
    margin-left: auto;
    margin-left: auto;
}
.question-icon {
    cursor: pointer;
}

.nav-justified .nav-item .nav-link {
    width: auto;
}

.submit-btn-color {
    background-color: #34989f !important;
}

label.consultationdateLabel {
    font-size: 20px;
}

.consultateDateBox {
    text-align: center;
    border: 1px solid #34989F;
    width: 150px;
    padding: 5px;
}
.selectfile{
    text-align: center;
    border: 1px solid #34989F;
    width: 150px;
    padding: 5px;
    border-radius: 10px;
}

.samplehistory {
    margin-top: 4rem;
}

.patientGenderAgeDetailDiv {
    display: flex;
}

.patientGenderAgeDetailDiv p {
    background-color: #cdcdcd;
    padding: 6px;
    width: 110px;
}

.form-control-number {
    width: 300px;
    border: 1px solid #00979e;
    text-align: center;
    padding: 5px;
}

h1.offer-consultationTextHeading {
    font-weight: bold;
}

.disableButton {
    opacity: 0.5;
}

.imagesTexts {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

/* .PatientRecordsPara {
    padding-left: 7px;
} */

.arrowRightImage {
    width: 34px;
    height: 34px;
    margin-top: 8px;
}

.SizeHeadingText {
    font-size: 20px;
}

.jan-six-image {
    width: 100%;
    /* height: 60; */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.borderForDateSheet {
    border: 2px solid #000000;
    /* width: 80%; */
    margin: auto;
    padding: 8px;
}

.prescriptionParaFont {
    font-size: 10px;
    margin-bottom: 0px;
    width: 87px;
}

.prescription-image {
    width: 50px;
    height: 50px;
}

.doctor {
    float: left;
    /* padding-top: 5px;
    padding-left: 16px; */
}

.font-disable {
    background-color: #cdcdcd;
    padding: 6px;
    width: 190px;
}

.font-cursor {
    text-align: right;
    cursor: pointer;
}

.imageUploadDetails {
    position: relative;
    width: 427px;
    border-radius: 0.25em;
    text-align: left;
    /* right: 55px; */
    /* bottom: 113px; */
    z-index: 99;
    margin-left:auto;
    margin-right: auto;
    border: 1px solid #000000;
}

.imageUploadDetails:before,
.imageUploadDetails:after {
    position: absolute;
    z-index: -1;
    content: '';
}

.imageUploadDetails:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: yellow;
}

.imageUploadDetails:before {
    border: solid 2px #242424d4;
    border-right: solid 3.5em yellow;
    bottom: 0.25em;
    left: 0.25em;
    width: 0;
    height: 1em;
    transform: rotate(140deg) skewX(75deg);
}

.card.card-box.cardDesign {
    border: 2px solid #000000;
    width: 65%;
}

.notesImg {
    width: 100%;
    /* height: 60%; */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img.img-check.icon {
    width: 50px;
    float: right;
}

.addbtnText {
    width: 100px;
    margin-left: -1rem;
}

.pastConsultDate {
    text-align: center;
    color: #000;
    font-weight: bold;
    margin-bottom: 0px;
}

.dateToday {
    font-weight: bold;
}

.otp-btn-send {
    outline: none;
    border: none;
    background-color: #f6802a;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    width: 110px;
}

.otp-btn-verify {
    outline: none;
    border: none;
    background-color: #00979e;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    width: 110px;
}

.image-fix.icon-colorblue {
    border: 3px solid #00979e;
}

.image-fix.icon {
    border: 2px solid #000000;
    width: 155px;
}

img.img-men.icon {
    width: 75px;
    height: 75px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.samplecaseHistoryText {
    color: #000;
    text-align: center;
    margin-top: 3rem;
}
button.butt.btn.btn-blue-color {
    /* float: right; */
    position: fixed;
    color: #fff;
    top: 600px;
    font-weight: bold;
    z-index: 999;
}

.navigation-list-item-blk {
    transition: 0.3s;
    /* transform: translateX(-100px); */
    transform: translateX(75px);
}

nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}


section.banner-text.offer-consultation {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 655px;
    padding: 30px 0px 50px 0px;
}

.banner-sub {
    text-align: center;
    margin-top: 20px;
    color: #000000;
}

.banner-sub-content {
    margin-top: 20px;
}

.show-navigation-list {
    transform: translateX(0px);
}

button.presign.btn.btn-blue-color.text-white {
    float: right;
    width: 40%;
    /* width: auto; */
    font-weight: bold;
    font-size: large;
    /* margin: 10px -60px 10px 0px; */
}

.rxix-menu li.nav-item a.nav-link {
    color: #00979e;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px
}

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}



.btn-text {
    text-align: center;
    margin: 25px;
    padding: 25px;
    color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #00979e;
    }
}


.col-4.mt-3 {
    width: 25%;
}

button.btn.btn-blue-color.text-white {
    width: 100%;
}

button.offer.btn.btn-blue-color.text-white {
    width: auto;
    font-weight: bold;
    font-size: large;
    margin-bottom: 30px;
}

.card.card-box {
    width: 35rem;
    margin: auto;
}

.card-title.card-text {
    margin: 5px;
}

/* .card-title1 {
    margin: 15px;
} */

.card-title2 p {
    width: 120px;
    text-align: center;
}


textarea.form-cont {
    width: 100%;
    margin: auto;
    display: block;
}

.form-cont p {
    /* width: 80%; */
    margin-bottom: auto;
    padding-left: 8px;
    font-size: 21px;
}

.two-Date {
    display: block;
    padding-left: 8px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:after {
    content: "";
}

/* nav.three-nav {
    float: right;
    position: relative;
    top: 52px;
    left: 70px;
    background: transparent;
} */
nav.three-nav {
    float: right;
    position: fixed;
    /* top: 52px; */
    right: 0;
    background: transparent;
}

nav.three-nav:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    /* margin-top: 25px; */
    margin-top: 30px;
}

/* @media screen and (max-width:1200px) {
    button.butt.btn.btn-blue-color {
        right: 83px
    }
} */

@media screen and (max-width:992px) {
    nav.three-nav ul.three-nav {
        text-align: center;
        /* margin-left: -72px; */
        margin-top: 0px
            /* margin-top: 77px; */
    }
}

nav.three-nav ul.three-nav li.three-nav {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav ul.three-nav li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    /* right: 70px; */
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav ul.three-nav li:nth-child(1):after {
    /* content: "dcba"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(2):after {
    /* content: "get"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(3):after {
    /* content: "join"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:nth-child(4):after {
    /* content: "access"; */
    line-height: 70px;
}

nav.three-nav ul.three-nav li:nth-child(5):after {
    /* content: "post"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:hover {
    /* transform: translateX(0px); */
    /* transform: translateX(70px); */
    /* transform: rotateY(-360deg); */
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav ul.three-nav li:hover:after {
    opacity: 1;
    /* transform: perspective(400px) rotateY(0deg) scale(1); */
    /* transform: perspective(400px) rotateY(180deg) scale(1); */
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav ul.three-nav li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav ul.three-nav li.three-nav div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu {
    list-style: none;
    position: relative;
    left: 35px;
    /* right: 35px; */
    /* top: 200px; */
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    .mobile-signupbtn {
        color: #fff !important;
        font-weight: bold;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }

    .card.card-box.cardDesign {
        border: none;
        width: 100%;
    }

    section.banner-text.offer-consultation {
        margin-top: 0px;
    }

    h1.offer-consultationTextHeading {
        font-size: 33px;
    }

    .offer-consultationText h3 {
        font-size: 22px;
    }

    .samplecaseHistoryText h2 {
        font-size: 28px;
    }

    .samplecaseHistoryText {
        margin-top: 1.5rem;
    }

    .samplecaseHistoryText {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 768px) {
.samplehistory {
    margin-top: 1.5rem;
}
.ditigal-consultation-box div p{
    font-size: 16px;
}
.ditigal-consultation-box{
 display: grid;
 grid-gap: 20px; 
 grid-template-columns: repeat(2, 1fr);
}
.digital-imgs{
    width: 70px;
}
.closeConsultation{
 width: 110px;
}
}
@media screen and (max-width: 576px) {
    h1.offer-consultationTextHeading {
        font-size: 25px;
    }

    .offer-consultationText h3 {
        font-size: 17px;
    }

    .samplecaseHistoryText h2 {
        font-size: 21px;
    }

    .form-control-number {
        width: 147px;
    }

    .card-title2 p {
        width: 65px;
        text-align: center;
        font-size: 12px;
        padding: 5px;
    }

    .SizeHeadingText {
        font-size: 15px;
    }

    .patientGenderAgeDetailDiv p {
        padding: 3px;
        width: 95px;
        font-size: 12px;
    }

    .pastConsultDate {
        font-size: 15px;
    }
}

@media screen and (max-width: 560px) {

    .form-cont p {
        font-size: 15px;
        padding: 0px;
    }
}

@media screen and (max-width:550px) {
    section.banner-text.offer-consultation {
        padding: 10px 0px 24px 0px;
    }
}
@media screen and (max-width:530px) {
    .prescription-image {
        width: 36px;
        height: 36px;
    }
    .imageUploadDetails {
        width: 315px;
    }
    .arrowRightImage {
    width: 20px;
    height: 20px;
    margin-top: 8px;
}
.imagesTexts {
padding-left:0px;
padding-right: 0px;
margin-right: 1px;
margin-left: 1px;
}
}
@media screen and (max-width:400px) {
    .prescriptionParaFont {
    font-size: 9px;
    margin-bottom: 0px;
    width: 55px;
}
.imageUploadDetails {
    width: 222px;
}
}

@media screen and (max-width:355px) {
    .upload-btn {
        font-size: 13px;
    }
}
</style>
